import React from 'react';

import FinEdBackground from '../components/financialEducation/finEdBackground/FinEdBackground';
import WebComponentsConfig from '../components/WebComponentsConfig';

const FinancialEducationLayout = ({
    theme,
    wizardHeader,
    children,
}) => {
    return (
        <WebComponentsConfig theme={theme}>
            <FinEdBackground
                svgOne={
                    <svg width="705" height="414" viewBox="0 0 705 414" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.1" d="M672.107 -263.58C651.892 -347.385 574.331 -382.88 538.077 -390.152C317.413 -449.673 -47.3313 -112.95 156.97 131.105C361.271 375.16 504.061 309.462 624.988 282.533C745.915 255.605 798.168 67.4826 729.959 -19.6332C661.75 -106.749 697.375 -158.823 672.107 -263.58Z" fill="url(#paint0_linear)" />
                        <defs>
                            <linearGradient id="paint0_linear" x1="912.643" y1="-216.713" x2="298.716" y2="-486.299" gradientUnits="userSpaceOnUse">
                                <stop stopColor="#60B848" />
                                <stop offset="1" stopColor="#009677" />
                            </linearGradient>
                        </defs>
                    </svg>
                }>
                <header>
                    {wizardHeader}
                </header>
                <main>
                    {children}
                </main>
            </FinEdBackground>
        </WebComponentsConfig>
    )
}

export default FinancialEducationLayout;
