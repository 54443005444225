import { createSelector } from 'reselect';
import { TOGGLE_STATUS } from '../../reducers/status';
import { getFinancialEducation } from './index';

export const getJourneys = createSelector(
    getFinancialEducation,
    (financialEducation) => financialEducation.journey && financialEducation.journey.journeys
);

export const getJourneyById = (journeyId) => {
    return createSelector(
        getJourneys,
        (journeys) => journeys && journeys.length && journeys.find((journey) => journey.journeyId === journeyId)
    );
};

export const getJourneyItems = (journeyId) => {
    return createSelector(
        getJourneyById(journeyId),
        (journey) => {
            return journey && journey.journeyItems;
        }
    );
};

export const getJourneyItem = (journeyId, journeyItemId) => {
    return createSelector(
        getJourneyItems(journeyId),
        (journeyItems) => journeyItems && journeyItems.find(
            (journeyItem) => journeyItem.itemId === journeyItemId
        )
    );
};

export const getIsJourneyItemCompleted = (journeyId, journeyItemId) => {
    return createSelector(
        getJourneyItem(journeyId, journeyItemId),
        (journeyItem) => journeyItem && journeyItem.isItemCompleted
    );
};

export const getExpandedJourneyItemId = (journeyId) => {
    return createSelector(
        getJourneyById(journeyId),
        journey => {
            return journey && journey.expandedJourneyItemId
        }
    )
}

export const getTotalJourneyItemsCompletedByJourneyId = (journeyId) => {
    return createSelector(
        getJourneyItems(journeyId),
        (journeyItems) => journeyItems && journeyItems.filter(item => item.isItemCompleted == true).length
    )
}

export const getTotalTimeToCompleteJourneyByJourneyId = (journeyId) => {
    return createSelector(
        getJourneyItems(journeyId),
        journeyItems => {
            let totalTime = 0;

            if (journeyItems) {
                journeyItems.forEach(item => {
                    totalTime += item.timeToComplete;
                });
            }

            return totalTime;
        }
    )
}

export const getTimeCompletedByJourneyId = (journeyId) => {
    return createSelector(
        getJourneyItems(journeyId),
        (journeyItems) => {
            let totalTimeCompleted = 0;
            if (journeyItems) {
                journeyItems.forEach(item => {
                    if (item.isItemCompleted) {
                        totalTimeCompleted += item.timeToComplete;
                    }
                })
            }
            return totalTimeCompleted;
        }
    )
}

export const getRemainingTimeByJourneyId = (journeyId) => {
    return createSelector(
        getTotalTimeToCompleteJourneyByJourneyId(journeyId),
        getTimeCompletedByJourneyId(journeyId),
        (totalTimeToCompleteJourney, timeCompleted) => {
            return totalTimeToCompleteJourney - timeCompleted;
        }
    )
}

export const getPercentageOfItemsCompletedByJourneyId = (journeyId) => {
    return createSelector(
        getTotalTimeToCompleteJourneyByJourneyId(journeyId),
        getTimeCompletedByJourneyId(journeyId),
        (totalTimeToCompleteJourney, timeCompleted) => {
            return (timeCompleted / totalTimeToCompleteJourney * 100)
        }
    )
}

export const getIsJourneyCompleted = (journeyId) => {
    return createSelector(
        getJourneyItems(journeyId),
        getTotalJourneyItemsCompletedByJourneyId(journeyId),
        (journeyItems, completedJourneyItems) => {
            if (!journeyItems || !completedJourneyItems) {
                return false;
            } else {
                return journeyItems.length === completedJourneyItems
            }
        }
    )
}

export const getUpNextRemainingCountdownTimeByJourneyId = (journeyId) => {
    return createSelector(
        getJourneyById(journeyId),
        (journey) => journey && journey.upNextRemainingCountdownTime
    )
}

export const showUpNextCountdownTimerByJourneyId = (journeyId) => {
    return createSelector(
        getJourneyById(journeyId),
        (journey) => journey && journey.showUpNextCountdownTimer === TOGGLE_STATUS.YES
    )
}

export const getdelightAnimationCountdownTimeByJourneyId = (journeyId) => {
    return createSelector(
        getJourneyById(journeyId),
        (journey) => journey && journey.delightAnimationCountdownTime
    )
}

export const isDelightAnimationCompletedByJourneyId = (journeyId) => {
    return createSelector(
        getJourneyById(journeyId),
        (journey) => journey && journey.delightAnimationCountdownTime === 0
    )
}

export const isJourneyCompletedAndAllAccordionsClosedByJourneyId = (journeyId) => {
    return createSelector(
        getIsJourneyCompleted(journeyId),
        getExpandedJourneyItemId(journeyId),
        (isJourneyCompleted, expandedJourneyItemId) => isJourneyCompleted && !expandedJourneyItemId 
    )
}
