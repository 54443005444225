import React from 'react';
import { graphql } from 'gatsby';
import { OmArticleVideoContentWrapper } from '@om/component-library-react';

const ArticleVideoContentWrapper = ({
    articleVideoContentWrapperContent,
}) => {
    if (!articleVideoContentWrapperContent) {
        return '';
    }
    const {
        uid,
        podcast_episode_id,
        podcast_series_id,
        video_url,
        video,
        button_action_type,
        open_tab,
        button_text,
        button_url,
        section_heading,
        section_sub_heading,
        section_caption,
        call_me_back
    } = articleVideoContentWrapperContent;

    return (
        <OmArticleVideoContentWrapper
            id={uid}
            videoUrl={video_url ? video_url.href : ''}
            video={video ? video.url : ''}
            buttonActionType={button_action_type}
            buttonText={button_text}
            buttonUrl={button_url ? button_url.href : ''}
            callMeBackObject={JSON.stringify(call_me_back)}
            openTab={open_tab}
            podcastSeriesId={podcast_series_id ? podcast_series_id : ''}
            podcastEpisodeId={podcast_episode_id ? podcast_episode_id : ''}>

            {
                section_heading ? <span slot="section-heading" dangerouslySetInnerHTML={{ __html: section_heading }} />
                    : ''
            }
            {
                section_sub_heading ? <span slot="section-sub-heading" dangerouslySetInnerHTML={{ __html: section_sub_heading }} />
                    : ''
            }
            {
                section_caption ? <span slot="section-caption" dangerouslySetInnerHTML={{ __html: section_caption }} />
                    : ''
            }
        </OmArticleVideoContentWrapper>
    );
};

export const pageQuery = graphql`
fragment ArticleVideoContentWrapper on Contentstack_article_video_content_wrapper {
    uid
    podcast_episode_id
    podcast_series_id
    video_url {
        ...Link
    }
    video {
        ...File
    }
    button_action_type
    open_tab
    button_text
    button_url {
        ...Link
    }
    section_heading
    section_sub_heading
    section_caption
    call_me_back {
        ...CallMeBack
    }
}
`;

export default ArticleVideoContentWrapper