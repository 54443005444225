import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { ThemeContext } from '../../context/ThemeContext';
import { OmIcon } from '../../../../component-library-react/src/components';
import OmButton from '../forms/OmButton';
import {
    setCurrentQuestion,
    setAnswer,
    clearValues,
    createNewQuiz,
} from '@om/redux/actions/quizAssessment/quiz';
import {
    getCurrentQuestion,
    getTotalQuestions,
    getFinalAnswer,
} from '@om/redux/selectors/quizAssessment/quiz';
import './Quiz.scss';

const Quiz = ({
    quizContent,
    slotName,
    onCompleteEvent,
}) => {
    const dispatch = useDispatch();
    const theme = React.useContext(ThemeContext);
    const [loadQuiz, setLoadQuiz] = useState(false);

    const {
        currentQuestion,
        totalQuestions,
        finalAnswerIndex
    } = useSelector(
        createStructuredSelector({
            currentQuestion: getCurrentQuestion(quizContent.uid),
            totalQuestions: getTotalQuestions(quizContent.uid),
            finalAnswerIndex: getFinalAnswer(quizContent.uid),
        })
    );

    useEffect(() => {
        if (!loadQuiz) {
            setTimeout(() => {
                setLoadQuiz(true);
            }, 1000)
        }
    }, [loadQuiz]);

    useEffect(() => {
        if (quizContent) {
            dispatch(createNewQuiz(quizContent.uid, quizContent.modular_blocks.length))
        }
    }, [quizContent, dispatch]);

    useEffect(() => {
        if (currentQuestion && totalQuestions && currentQuestion >= totalQuestions) {
            onCompleteEvent();
        }
    }, [currentQuestion, totalQuestions, onCompleteEvent]);

    if (!quizContent) {
        return '';
    }

    const {
        uid,
        modular_blocks,
        result_a,
        result_b,
        result_c,
        result_d,
        disclaimer,
        primary_button
    } = quizContent;

    const results = [
        result_a,
        result_b,
        result_c,
        result_d
    ]

    const selectAnswer = (index) => {
        dispatch(setAnswer(uid, index));
        dispatch(setCurrentQuestion(uid, currentQuestion + 1));
    };

    const questionsAndAnswers = modular_blocks.map((modularBlock, index) => {
        const question = modularBlock.question_and_answer_block.question;
        const answers = modularBlock.question_and_answer_block.answer;

        const answerComponents = answers.map((answer, index) => {
            return (
                <li
                    key={index}
                    className="answer-option"
                    onClick={() => selectAnswer(index)}
                >
                    {answer} <OmIcon iconName="arrow_forward" />
                </li>
            );
        });

        return (
            <div key={index} className="question-answer-wrapper">
                <h5 className="question">
                    <strong>{question}</strong>
                </h5>
                <ul className="answers">{answerComponents}</ul>
            </div>
        );
    });

    const progressBar = () => {
        return (
            <div className="progressBar">
                {modular_blocks.map((_, index) => {
                    if (index < currentQuestion) {
                        return <div key={index} className="progressBarSegment completed" />
                    } else if (index === currentQuestion) {
                        return <div key={index} className="inProgressBarSegment" />
                    }

                    return <div key={index} className="progressBarSegment" />
                })}
            </div>
        );
    };

    const getResults = () => {
        const result = results[finalAnswerIndex];
        return (
            result && (
                <div className="result-block">
                    {result.heading && <h5 className="result-heading"><strong dangerouslySetInnerHTML={{ __html: result.heading }}></strong></h5>}
                    <p className="result-description" dangerouslySetInnerHTML={{ __html: result.description }}></p>
                    {disclaimer && <p className="result-disclaimer" dangerouslySetInnerHTML={{ __html: disclaimer }} />}
                    <div className="result-buttons">
                        <OmButton
                            onClick={() => dispatch(clearValues(uid))}
                            type='secondary'
                            withIcon={true}
                            iconName='reload'
                            size='small'
                            text='Retake quiz'
                        />
                        {primary_button && primary_button.action && primary_button.action.length > 0 &&
                            <OmButton
                                type='primary'
                                withIcon={false}
                                size='small'
                                text={primary_button && primary_button.display_text}
                                actionObject={
                                    primary_button && primary_button.action && primary_button.action[0]
                                }
                            />}
                    </div>
                </div>
            )
        );
    };

    const isQuizCompleted = currentQuestion >= totalQuestions;
    return loadQuiz ? (
        <div slot={slotName} className={`${theme} quiz-container`}>
            <div className='quiz-wrapper'>
                {isQuizCompleted ? (
                    <h5 className="question-counter">
                        <strong>Your quiz results</strong>
                    </h5>
                ) : (
                    <>
                        <h5 className="question-counter">
                            <strong>
                                Take the quiz, {currentQuestion + 1}/
                                {totalQuestions}
                            </strong>
                        </h5>
                        {progressBar()}
                    </>
                )}

                {questionsAndAnswers.filter(
                    (_, index) => index === currentQuestion
                )}
                {isQuizCompleted && getResults()}
            </div>
        </div>
    ) : '';
};

export const pageQuery = graphql`
    fragment Quiz on Contentstack_quiz {
        id 
        uid
        modular_blocks {
            question_and_answer_block {
                answer
                question
            }
        }
        result_a {
            heading
            description
        }
        result_b {
            heading
            description
        }
        result_c {
            heading
            description
        }
        result_d {
            heading
            description
        }
        disclaimer
        primary_button {
            ...QuizPrimaryButton
        }
    }
`

export default Quiz;
