import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    setUpNextCountdownTime,
    toggleShowUpNextCountdownTime
} from '@om/redux/actions/financialEducation/journey';
import {
    getExpandedJourneyItemId,
    getIsJourneyCompleted,
    getUpNextRemainingCountdownTimeByJourneyId,
    isDelightAnimationCompletedByJourneyId,
    showUpNextCountdownTimerByJourneyId
} from '@om/redux/selectors/financialEducation/journey';

import { ThemeContext } from '../../../context/ThemeContext';
import { OmIcon, OmCountdownTimer } from '@om/component-library-react';
import JourneyAccordionItem from '../JourneyAccordionItem';
import FinEdProgressBar from '../finEdProgressBar/FinEdProgressBar';

import './VerticalAccordionStepper.scss';

const getStartText = (isJourneyCompleted) => {
    if (isJourneyCompleted) {
        return 'All completed, hope that was educational';
    }
    return 'Start your journey';
}

const getEndText = (hasUpNextComponent, isJourneyCompleted, showUpNextCountdownTimer, remainingTime, hasDelightCompleted) => {
    if (isJourneyCompleted) {
        if (hasUpNextComponent && showUpNextCountdownTimer && hasDelightCompleted) {
            return <p className="no-bottom-margin no-top-margin">Up next <strong>in {remainingTime}</strong></p>;
        }
        return <p className="no-bottom-margin no-top-margin">Up next</p>;
    }
    return <p className="no-bottom-margin no-top-margin">The end is just the beginning</p>;
}

const VerticalAccordionStepper = ({
    journeyId,
    journeyItems,
    hasUpNextComponent,
    accordionBackgroundSideImage,
}) => {

    const [displayCompletedIcon, setDisplayCompletedIcon] = useState(false);

    const theme = React.useContext(ThemeContext);

    const dispatch = useDispatch();

    const {
        expandedJourneyItemId,
        isJourneyCompleted,
        showUpNextCountdownTimer,
        hasDelightCompleted,
        remainingCountdownTime
    } = useSelector(
        createStructuredSelector({
            expandedJourneyItemId: getExpandedJourneyItemId(journeyId),
            isJourneyCompleted: getIsJourneyCompleted(journeyId),
            showUpNextCountdownTimer: showUpNextCountdownTimerByJourneyId(journeyId),
            hasDelightCompleted: isDelightAnimationCompletedByJourneyId(journeyId),
            remainingCountdownTime: getUpNextRemainingCountdownTimeByJourneyId(journeyId),
        })
    );

    useEffect(() => {
        if (showUpNextCountdownTimer) {
            if (remainingCountdownTime === 0) {
                dispatch(toggleShowUpNextCountdownTime(journeyId));
                const nextButtonElement = document.querySelector('.up-next-card-wrapper om-button:first-of-type a');
                if (nextButtonElement) {
                    nextButtonElement.click();
                }

            } else if (remainingCountdownTime > 0) {
                setTimeout(() => { dispatch(setUpNextCountdownTime(journeyId, remainingCountdownTime - 1)) }, 1000);
            }
        }
    }, [journeyId, showUpNextCountdownTimer, remainingCountdownTime, dispatch])

    useEffect(() => {
        setTimeout(() => {
            setDisplayCompletedIcon(true);
        }, 1000);
    }, [displayCompletedIcon])

    useEffect(() => {
        if (remainingCountdownTime > 0 && remainingCountdownTime != 15 && showUpNextCountdownTimer) {
            dispatch(toggleShowUpNextCountdownTime(journeyId));
        }

        setTimeout(() => {
            const element = document.getElementById(expandedJourneyItemId);
            if (element) {
                const yOffset = -40;
                const y =
                    element.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }, 1000);
    }, [])

    if (!journeyItems) {
        return '';
    }

    let expandedJourneyItemModularBlocks;
    const expandedJourneyItem = journeyItems.find(item => item.uid === expandedJourneyItemId)
    if (expandedJourneyItem) {
        expandedJourneyItemModularBlocks = expandedJourneyItem.modular_blocks
    }

    return (
        <div className={`${theme} vertical-stepper-container`}>
            <div
                className="accordion-background"
                style={{ backgroundImage: `url(${accordionBackgroundSideImage && accordionBackgroundSideImage.url})` }}
            />

            <div className="vertical-stepper-wrapper">
                <div className="vertical-bar-wrapper">
                    {displayCompletedIcon && isJourneyCompleted ?
                        <div className="start-icon completed">
                            <OmIcon iconName='check' width="20" height="20" />
                        </div> :
                        <div className="start-icon" />
                    }

                    <div className="start-text">
                        <p className="no-bottom-margin no-top-margin">
                            {getStartText(isJourneyCompleted)}
                        </p>
                    </div>

                    {hasUpNextComponent && isJourneyCompleted && hasDelightCompleted && showUpNextCountdownTimer ? (
                        <div className="end-timer-icon">
                            <OmCountdownTimer
                                totalTime={15}
                                timeRemaining={remainingCountdownTime} />
                        </div>
                    ) : (
                        displayCompletedIcon && isJourneyCompleted
                            ? <div className="end-icon completed" />
                            : <div className="end-icon" />
                    )}

                    <div id='end-text' className="end-text">
                        {getEndText(hasUpNextComponent, isJourneyCompleted, showUpNextCountdownTimer, remainingCountdownTime, hasDelightCompleted)}
                    </div>
                </div>
                <FinEdProgressBar
                    totalNumberOfSteps={journeyItems.length}
                    journeyId={journeyId}
                />
                {journeyItems.map((item) => {
                    return (
                        <JourneyAccordionItem
                            id={item.uid}
                            journeyId={journeyId}
                            key={item.uid}
                            journeyAccordionContent={item}
                            expandedJourneyItemId={expandedJourneyItemId}
                            expandedModularBlocks={expandedJourneyItemModularBlocks}
                        />
                    );
                })}
            </div>
        </div >
    );
};

export default VerticalAccordionStepper;
