import React from 'react';
import { graphql } from 'gatsby';
import { ThemeContext } from '../../context/ThemeContext';

import { OmIcon } from '@om/component-library-react';

import './VideoOverlayWrapper.scss';

const getVideoIdByVideoUrl = (videoUrl) => {
    if (videoUrl && videoUrl.href) {
        const videoUrlStringArray = videoUrl.href.split('/');
        return videoUrlStringArray[videoUrlStringArray.length - 1];
    }
    return '';
}

const getThumbnailURL = (videoId) => {
    if (videoId !== '') {
        return `https://i.ytimg.com/vi/${videoId}/hq720.jpg`;
    }
    return '';
}

const playButton = (videoId, onCompleteEvent) => {
    if (videoId !== '') {
        const videoElement = document.getElementById(videoId);
        if (videoElement) {
            videoElement.src += '&autoplay=1';
            videoElement.style.display = 'block';
        }

        const contentOverlayContainer = document.querySelector(`div[id=${videoId}]`);
        if (contentOverlayContainer) {
            contentOverlayContainer.style.display = 'none';
        }
    }
    onCompleteEvent && onCompleteEvent();
}

const VideoOverlayWrapper = ({
    videoOverlayWrapperContent,
    onCompleteEvent,
    slotName,
}) => {
    const theme = React.useContext(ThemeContext);

    if (!videoOverlayWrapperContent) {
        return '';
    }

    const {
        video_url,
        video_heading,
        video_caption
    } = videoOverlayWrapperContent;

    return (
        <div slot={slotName} className={`${theme} video-overlay-wrapper`}>
            <iframe
                id={getVideoIdByVideoUrl(video_url)}
                className="video-frame"
                src={video_url.href}
                allow="autoplay; encrypted-media"
                allowFullScreen
                frameBorder="0"
                scrolling="no" />
            <div id={getVideoIdByVideoUrl(video_url)} className="content-overlay-container" onClick={() => playButton(getVideoIdByVideoUrl(video_url), onCompleteEvent)}>
                <div id="cover-image-container" className="cover-image-container">
                    <img loading="lazy" alt="cover-image" className="cover-image" src={getThumbnailURL(getVideoIdByVideoUrl(video_url))} />
                    <button id="play-button" className="play-button"><OmIcon iconName="play" /></button>
                </div>
                <div className="content-overlay" >
                    <div className="content">
                        <div className="heading" dangerouslySetInnerHTML={{ __html: video_heading }} />
                        <div className="caption" dangerouslySetInnerHTML={{ __html: video_caption }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export const pageQuery = graphql`
    fragment VideoOverlayWrapper on Contentstack_video_overlay_wrapper {
        __typename
        video_url {
            href
        }
        video_heading
        video_caption
    }
`

export default VideoOverlayWrapper;
