import React from 'react';

import { OmIcon, OmWizardHeader } from '@om/component-library-react';
import { ThemeContext } from '../../../context/ThemeContext';

import './FinEdWizardHeader.scss';

const backButtonHandler = (backButton) => {
    if(backButton && backButton.url && backButton.url!== ''){
        window.location.href = backButton.url;
    } else {
        return window.history.back();
    }
}

const getBackHeading = (backButton) => {
    if(backButton && backButton.heading && backButton.heading !== ''){
        return backButton.heading;
    }
    return 'My Goals';
}

const FinEdWizardHeader = ({
    displayMobileWizardHeader,
    heading,
    backButton,
}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <>
            <OmWizardHeader>
                <span slot="sub-title" className="no-top-margin no-bottom-margin hover-span" onClick={() => backButtonHandler(backButton)}>
                    <OmIcon iconName="arrow_back" />
                    <span className="icon-description-span">{getBackHeading(backButton)}</span>
                </span>
            </OmWizardHeader>
            {
                displayMobileWizardHeader &&
                <div className={`${theme} mobile-wizard-header-wrapper`}>
                    <div className="mobile-wizard-header" onClick={() => backButtonHandler(backButton)}>
                        <OmIcon iconName="arrow_back" />
                        <p className="description small no-margin">{heading}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default FinEdWizardHeader;
