import React from 'react';
import TabsIFrame from './TabsIFrame';
import OMIFrame from './iFrame';
import VideoOverlayWrapper from './videoOverlayWrapper/VideoOverlayWrapper';

const iFrames = ({ iFramesContent, onCompleteEvent }) => {

    if (!iFramesContent)
        return '';

    if (iFramesContent.__typename === 'Contentstack_iframe')
        return (<OMIFrame iFrameContent={iFramesContent} />)

    if (iFramesContent.__typename === 'Contentstack_tabs_iframe')
        return (<TabsIFrame TabsIFrameContent={iFramesContent} />);

    if (iFramesContent.__typename === 'Contentstack_video_overlay_wrapper')
        return (<VideoOverlayWrapper videoOverlayWrapperContent={iFramesContent} onCompleteEvent={onCompleteEvent} />);

    return '';
}

export default iFrames;