import React from 'react';
import { graphql } from 'gatsby';
import { OmArticleImage } from '@om/component-library-react';

const ArticleImage = ({
    articleImageContent
}) => {
    const {
        uid,
        image_url,
        image_title,
        image_alt,
        image_disclaimer
    } = articleImageContent

    if(!articleImageContent){
        return '';
    }

    return (
        <OmArticleImage
            id={uid}
            imageUrl={image_url ? image_url.url : ''}
            imageTitle={image_title ? image_title : ''}
            imageAlt={image_alt ? image_alt : ''}
            imageDisclaimer={image_disclaimer ? image_disclaimer : ''}
        />
    );
};

export const pageQuery = graphql`
fragment ArticleImage on Contentstack_article_image{
    id
    uid
    image_url {
    	...File
    }
    image_title
    image_alt
    image_disclaimer
}
`;

export default ArticleImage
