import React from 'react';
import { graphql } from 'gatsby';

import { ThemeContext } from '../../../context/ThemeContext';

import './FinEdDisclaimer.scss';

const FinEdDisclaimer = ({
    disclaimerContent
}) => {
    
    const theme = React.useContext(ThemeContext);

    if (!disclaimerContent) {
        return '';
    }


    const {
        disclaimer_text,
        disclaimer_url,
        privacy_policy_url,
        cookie_policy_url,
        description,
    } = disclaimerContent

    const renderLinks = () => {
        return (
            <span className="links-container">
                <h6>
                    {disclaimer_text && <em>{disclaimer_text}</em>}

                    {disclaimer_url || privacy_policy_url 
                        ? <strong > | </strong> 
                        : ''}

                    <span>
                        {disclaimer_url 
                            ? <a href={disclaimer_url.href}>{disclaimer_url.title ?? 'Disclaimer'}</a> 
                            : ''}
                        {privacy_policy_url 
                            ? <a href={privacy_policy_url.href}>{privacy_policy_url.title ?? 'Privacy Notice'}</a> 
                            : ''}
                        {cookie_policy_url 
                            ? <a href={cookie_policy_url.href}>{cookie_policy_url.title ?? 'Cookie Policy'}</a> 
                            : ''}
                    </span>
                </h6>
            </span>
        );
    }

    return (
        <div className={`${theme} fin-ed-disclaimer-container`}>
            <div className='disclaimer-wrapper'>
                {description && <div dangerouslySetInnerHTML={{ __html: description}}/>}
                {renderLinks()}
            </div>
        </div>
    )
}

export const pageQuery = graphql`
    fragment FinEdDisclaimer on Contentstack_financial_education_disclaimer {
        disclaimer_text
        disclaimer_url {
            ...Link
        }
        privacy_policy_url {
            ...Link
        }
        cookie_policy_url {
            ...Link
        }
        description
    }
`;

export default FinEdDisclaimer;
