import React from 'react';
import { graphql } from 'gatsby';

import { OmIcon } from '@om/component-library-react';
import OmButton from '../../forms/OmButton';

import './UpNextCard.scss';

const getIcon = (icon, icon_name) => {
    if (icon || icon_name) {
        if (icon_name) {
            return <OmIcon iconName={icon_name} />
        }
        return <OmIcon imageUrl={icon.url} />
    }
    return '';
}

const UpNextCard = ({
    upNextCardContent,
    displayCancelButton,
    cancelTimerEvent,
}) => {
    
    if (!upNextCardContent) {
        return '';
    }

    const {
        icon,
        icon_name,
        description,
        primary_button
    } = upNextCardContent;

    return (
        <div className="up-next-card-wrapper">
            <div className="card-background">
                <svg width="600" height="430" viewBox="100 20 350 420" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.2" d="M254.945 426.154C296.356 451.582 341.452 434.903 358.824 423.385C470.628 359.374 482.346 79.8 303.668 63.6038C124.989 47.4075 94.0982 130.448 56.5158 189.308C18.9334 248.168 72.9014 344.085 134.797 351.743C196.693 359.4 203.181 394.369 254.945 426.154Z" fill="url(#paint0_linear)" />
                    <defs>
                        <linearGradient id="paint0_linear" x1="140.238" y1="503.123" x2="492.665" y2="366.564" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#60B848" />
                            <stop offset="1" stopColor="#009677" />
                        </linearGradient>
                    </defs>
                </svg>

            </div>
            <div className="card-content">
                <div className="card-icon">{getIcon(icon, icon_name)}</div>
                <div className="card-description" dangerouslySetInnerHTML={{ __html: description }} />
                <div className="card-button">
                    <OmButton
                        size="small"
                        type="primary"
                        text={primary_button && primary_button.display_text}
                        disabled={false}
                        withIcon={true}
                        actionObject={
                            primary_button && primary_button.action && primary_button.action[0]
                        }
                    />
                    {displayCancelButton &&
                        <OmButton
                            size="small"
                            type="secondary"
                            text="Cancel"
                            disabled={false}
                            withIcon={false}
                            onClick={() => cancelTimerEvent()}
                        />}
                </div>
            </div>
        </div >
    )
}

export const pageQuery = graphql`
    fragment UpNextCard on Contentstack_up_next_card {
        id
        uid
        icon {
            ...File
        }
        icon_name
        description
        primary_button {
            ...UpNextCardPrimaryButton
        }
    } 
`

export default UpNextCard;
