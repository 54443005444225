import React from 'react';
import { graphql } from 'gatsby';
import { OmIframe } from '@om/component-library-react';

const iFrame = ({ iFrameContent, inTabs, slot }) => {
    if (!iFrameContent) {
        return '';
    }

    const { uid, title, iframe_url, desktop_height, mobile_height, max_width } = iFrameContent;

    return (
        <OmIframe
            slot={slot}
            id={uid}
            iframeSrc={iframe_url}
            iframeTitle={title}
            heightDesktop={desktop_height}
            heightMobile={mobile_height}
            maxWidth={max_width}
            inTabs={inTabs}
        />
    );
};

export const pageQuery = graphql`
	fragment IFrame on Contentstack_iframe {
        __typename
		uid
		title
		iframe_url
		desktop_height
		mobile_height
		max_width
	}
`;

export default iFrame;
