import React from 'react';

import { ThemeContext } from '../../../context/ThemeContext.js';
import { OmIcon } from '@om/component-library-react';

import './JourneyHeading.scss';

const getIcon = (icon, icon_name) => {
    if (icon || icon_name) {
        if (icon_name) {
            return <OmIcon iconName={icon_name} />
        }
        return <OmIcon imageUrl={icon.url} />
    }
    return '';
}

const JourneyHeading = ({
    heading,
    description,
    icon,
    icon_name
}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <div className={`${theme} journey-heading`}>
            <span className="icon-span">{getIcon(icon, icon_name)}</span>
            <span className="text-span">
                <h4 className="no-margin">{heading}</h4>
                <h6 className="no-bottom-margin">{description}</h6>
            </span>
        </div>
    )
}

export default JourneyHeading;
