import React from 'react';
import { graphql } from 'gatsby';
import { OmTabsIframe } from '@om/component-library-react';
import OMIFrame from './iFrame'

const TabsIFrame = ({
    TabsIFrameContent,
    slot
}) => {
    const {
        use_heading_as_h1,
        is_extended,
        section_heading,
        section_caption,
        tab_text_one,
        iframe_one,
        tab_text_two,
        iframe_two,
        tab_text_three,
        iframe_three,
        tab_text_four,
        iframe_four,
        tab_text_five,
        iframe_five,
        tab_text_six,
        iframe_six
    } = TabsIFrameContent;

    return (
        <OmTabsIframe
            slot={slot}
            isExtended={is_extended}
            tabTextOne={tab_text_one}
            tabTextTwo={tab_text_two}
            tabTextThree={tab_text_three}
            tabTextFour={tab_text_four}
            tabTextFive={tab_text_five}
            tabTextSix={tab_text_six}
        >
            {
                use_heading_as_h1 ? (section_heading ? <h1 slot="section-heading" dangerouslySetInnerHTML={{__html: section_heading}} /> :'') :
                    (section_heading ? <span slot="section-heading" dangerouslySetInnerHTML={{__html: section_heading}} /> : '')
            }
            {section_caption &&
                <span slot="section-caption" dangerouslySetInnerHTML={{__html: section_caption}} />
            }
            {iframe_one &&
                <OMIFrame slot={'iframe-one'} iFrameContent={iframe_one[0]} inTabs={true} />
            }
            {iframe_two &&
                <OMIFrame slot={'iframe-two'} iFrameContent={iframe_two[0]} inTabs={true} />
            }
            {iframe_three &&
                <OMIFrame slot={'iframe-three'} iFrameContent={iframe_three[0]} inTabs={true} />
            }
            {iframe_four &&
                <OMIFrame slot={'iframe-four'} iFrameContent={iframe_four[0]} inTabs={true} />
            }
            {iframe_five &&
                <OMIFrame slot={'iframe-five'} iFrameContent={iframe_five[0]} inTabs={true} />
            }
            {iframe_six &&
                <OMIFrame slot={'iframe-six'} iFrameContent={iframe_six[0]} inTabs={true} />
            }
        </OmTabsIframe>
    );

};

export const pageQuery = graphql`
fragment TabsIFrame on Contentstack_tabs_iframe {
    __typename
    uid
    title
    use_heading_as_h1
    is_extended
    section_heading
    section_caption
    tab_text_one
    iframe_one {
        ...IFrame
    }
    tab_text_two
    iframe_two {
        ...IFrame
    }
    tab_text_three
    iframe_three {
        ...IFrame
    }
    tab_text_four
    iframe_four {
        ...IFrame
    }
    tab_text_five
    iframe_five {
        ...IFrame
    }
    tab_text_six
    iframe_six {
        ...IFrame
    }
}`;

export default TabsIFrame;
