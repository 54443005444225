import React from 'react';
import { graphql } from 'gatsby';
import { OmQuoteBlock } from '@om/component-library-react';

const QuoteBlock = ({
    quoteBlockContent,
}) => {
    if (!quoteBlockContent) {
        return '';
    }

    const {
        uid,
        quote,
        author,
        role,
        company
    } = quoteBlockContent;

    return (
        <OmQuoteBlock
            id={uid}
        >
            <span slot="quote" dangerouslySetInnerHTML={{ __html: quote }} />
            <span slot="author" dangerouslySetInnerHTML={{ __html: author }} />
            <span slot="role" dangerouslySetInnerHTML={{ __html: role }} />
            <span slot="company" dangerouslySetInnerHTML={{ __html: company }} />
        </OmQuoteBlock>
    );
};

export const pageQuery = graphql`
fragment QuoteBlock on Contentstack_quote_block {
    id
    uid
    quote
    author
    role
    company
}
`;

export default QuoteBlock