import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { graphql } from 'gatsby';

import {
    setExpandedJourneyItem,
    updateJourneyItem,
} from '@om/redux/actions/financialEducation/journey';
import { getIsJourneyItemCompleted } from '@om/redux/selectors/financialEducation/journey';

import { OmIcon, OmJourneyAccordionItem } from '@om/component-library-react';
import FinEdJourneyItemModularBlock from './FinEdJourneyItemModularBlock';

const getIconTitle = (icon) => {
    switch (icon) {
    case 'fin_ed_microphone':
        return 'Podcast';
    case 'fin_ed_calculator':
        return 'Calculator';
    case 'fin_ed_pages':
        return 'Article';
    case 'fin_ed_youtube':
        return 'Youtube';
    case 'fin_ed_information':
        return 'Information';
    case 'fin_ed_download':
        return 'Download';
    case 'fin_ed_playlist_add_check':
        return 'Checklist';
    default:
        return '';
    }
};

const isApplicationContainerBlock = (modularBlock) => {
    return (
        modularBlock.application_container_block &&
        modularBlock.application_container_block.application_container.length >
        0
    );
};

const isArticleCarouselBlock = (modularBlock) => {
    return (
        modularBlock.carousel_block &&
        modularBlock.carousel_block.carousel.length &&
        modularBlock.carousel_block.carousel[0].__typename ===
        'Contentstack_carousel_article'
    );
};

const isDownloadCarouselBlock = (modularBlock) => {
    return (
        modularBlock.carousel_block &&
        modularBlock.carousel_block.carousel.length &&
        modularBlock.carousel_block.carousel[0].__typename ===
        'Contentstack_download_carousel'
    );
};

const isProductCarouselBlock = (modularBlock) => {
    return (
        modularBlock.carousel_block &&
        modularBlock.carousel_block.carousel.length &&
        (modularBlock.carousel_block.carousel[0].__typename ===
            'Contentstack_product_carousel' ||
            modularBlock.carousel_block.carousel[0].__typename ===
            'Contentstack_tabs_carousel_product')
    );
};

const isVideoOverlayBlock = (modularBlock) => {
    return (
        modularBlock.iframe_block &&
        modularBlock.iframe_block.iframe[0].__typename ===
        'Contentstack_video_overlay_wrapper'
    );
};

const isCustomCompleteBlock = (modularBlock)=> {
    return (
        (modularBlock.quiz_block && modularBlock.quiz_block.quiz.length) ||
        isVideoOverlayBlock(modularBlock) ||
        isApplicationContainerBlock(modularBlock) ||
        isArticleCarouselBlock(modularBlock) ||
        isDownloadCarouselBlock(modularBlock) ||
        isProductCarouselBlock(modularBlock)
    );
}

const isCustomCompleteBlocks = (modularBlocks) => {
    const customBlock = modularBlocks && modularBlocks.find((modularBlock) => isCustomCompleteBlock(modularBlock));
    return customBlock;
};

const onExpandEvent = (e, expandedJourneyItemId, expandedModularBlocks, modularBlocks, journeyId, itemId, dispatch) => {
    if (e.detail.expandedStatus) {
        if (expandedJourneyItemId && !isCustomCompleteBlocks(expandedModularBlocks)) {
            dispatch(updateJourneyItem(journeyId, expandedJourneyItemId));
        }

        dispatch(setExpandedJourneyItem(journeyId, itemId));
        setTimeout(() => {
            const element = document.getElementById(itemId);
            if (element) {
                const yOffset = -40;
                const y =
                    element.getBoundingClientRect().top +
                    window.pageYOffset +
                    yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
            }
        }, 100);
    } else {
        dispatch(setExpandedJourneyItem(journeyId, null));
        if (!isCustomCompleteBlocks(modularBlocks)) {
            dispatch(updateJourneyItem(journeyId, itemId));
        }
    }
};

const onCompleteEvent = (journeyId, itemId, dispatch) => {
    dispatch(updateJourneyItem(journeyId, itemId));
};

const JourneyAccordionItem = ({
    id,
    journeyId,
    journeyAccordionContent,
    expandedModularBlocks,
    expandedJourneyItemId
}) => {
    const dispatch = useDispatch();

    const { isCompleted } = useSelector(
        createStructuredSelector({
            isCompleted: getIsJourneyItemCompleted(journeyId, id),
        })
    );

    if (!journeyAccordionContent) {
        return '';
    }

    const {
        heading,
        time,
        icon,
        modular_blocks,
    } = journeyAccordionContent;

    return (
        <OmJourneyAccordionItem
            id={id}
            identifier={id}
            heading={heading}
            journeyItemTime={time}
            isCompleted={isCompleted}
            expanded={expandedJourneyItemId === id}
            onExpandJourneyAccordion={(e) =>
                onExpandEvent(
                    e, 
                    expandedJourneyItemId, 
                    expandedModularBlocks,
                    modular_blocks, 
                    journeyId, 
                    id, 
                    dispatch
                )
            }
        >
            <OmIcon
                slot="left-icon"
                width={28}
                height={28}
                iconName={icon}
                title={getIconTitle(icon)} />
            <div slot="panel-content">
                {modular_blocks
                    ? modular_blocks.map((value, index) => {
                        const isCustom = isCustomCompleteBlock(value)
                        return (
                            <FinEdJourneyItemModularBlock
                                journeyItemId={id}
                                key={index}
                                modularBlock={value}
                                isCustomCompleteBlock={isCustom}
                                onCompleteEvent={() =>
                                    onCompleteEvent(
                                        journeyId,
                                        id,
                                        dispatch
                                    )
                                }
                            />
                        );
                    })
                    : ''}
            </div>
        </OmJourneyAccordionItem>
    );
};

export const pageQuery = graphql`
    fragment JourneyAccordionItem on Contentstack_journey_accordion_item {
        id
        uid
        heading
        time
        icon
        modular_blocks {
            ...FinEdJourneyItemModularBlock
        }
    }
`;

export default JourneyAccordionItem;
