import { createSelector } from 'reselect';
import { getQuizzes } from './index';

export const getQuizById = (quizId) => {
    return createSelector(
        getQuizzes,
        (quizzes) => quizzes && quizzes.length && quizzes.find(quiz => quiz.quizId === quizId)
    );
}

export const getCurrentQuestion = (quizId) => {
    return createSelector(
        getQuizById(quizId),
            (quiz) => quiz && quiz.currentQuestion
        );
}

export const getTotalQuestions = (quizId) => {
    return createSelector(
        getQuizById(quizId),
        (quiz) => quiz && quiz.totalQuestions
    );
}

export const getAnswers = (quizId) => {
    return createSelector(
        getQuizById(quizId), 
        (quiz) => quiz && quiz.answers
    );
}

export const getFinalAnswer = (quizId) => {
    return createSelector(
        getAnswers(quizId), 
        (answers) => {
            const frequencies = {};
            if (answers) {
                for (let answer of answers) {
                    if (!frequencies[answer]) {
                        frequencies[answer] = 1;
                    } else {
                        frequencies[answer] = frequencies[answer] + 1;
                    }
                }
            }

            const freqValues = Object.values(frequencies);
            const freqKeys = Object.keys(frequencies);
            const max = Math.max(...freqValues);
            return parseInt(freqKeys[freqValues.indexOf(max)]);
        }
    );
};
