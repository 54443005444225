import React from 'react';
import { graphql } from 'gatsby';

import { ThemeContext } from '../../../context/ThemeContext';

import './Checklist.scss';

const getChecklistItem = (checklistItem, index) => {
    return (
        <div key={index} id={`item-${index}`} className="checklist-item">
            <div className="heading">
                <span>
                    <strong>{index + 1}.</strong>
                </span>{' '}
                <h6 className="no-margin">
                    <strong>{checklistItem.heading}</strong>
                </h6>
            </div>
            <div className="items">
                {checklistItem.items.length &&
                    checklistItem.items.map((item, index) => {
                        return (
                            <div className="item" key={index}>
                                <h6 className="small no-bottom-margin">
                                    <strong>{item.item_heading}</strong>
                                </h6>
                                <div className="item-description" dangerouslySetInnerHTML={{__html: item.description }}/>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

const getChecklistItems = (checklistItems) => {
    return (
        checklistItems.length &&
        checklistItems.map((checklistItem, index) => {
            return getChecklistItem(checklistItem, index);
        })
    );
};

const scrollToId = (id) => {
    const element = document.getElementById(`item-${id}`);
    const container = document.getElementById('checklist-container')
    if (element && container) {
        const parentTopPos = element.parentNode.offsetTop
        const topPos = element.offsetTop;
        container.scrollTo({top: topPos - parentTopPos, behavior: 'smooth'}) 
    }
};

const Checklist = ({ checklistContent }) => {
    
    const theme = React.useContext(ThemeContext);

    if (!checklistContent) {
        return '';
    }
    
    const { checklist_items, checklist_height } = checklistContent;

    return (
        <div className={`${theme} checklist-container`}>
            <div className="checklist-wrapper">
                <div
                    className="checklist-items"
                    id='checklist-container'
                    style={{ height: `${checklist_height}px` }}
                >
                    {getChecklistItems(checklist_items)}
                </div>

                <div className="checklist-buttons-container">
                    {checklist_items.map((_, index) => {
                        return (
                            <div
                                key={index}
                                className='checklist-button'
                                onClick={() =>
                                    scrollToId(
                                        index
                                    )
                                }
                            >
                                {index + 1}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export const pageQuery = graphql`
    fragment Checklist on Contentstack_checklist {
        id
        uid
        checklist_height
        checklist_items {
            heading
            items {
                description
                item_heading
            }
        }
    }
`;

export default Checklist;
