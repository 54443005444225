import React from 'react';
import { graphql } from 'gatsby';
import { OmProTip } from '@om/component-library-react';

const ProTip = ({
    proTipContent,
}) => {
    if (!proTipContent) {
        return '';
    }

    const {
        uid,
        heading,
        message,
    } = proTipContent;

    if (!heading && !message) {
        return '';
    }

    return (
        <OmProTip id={uid}>
            { heading ? <p slot="heading" dangerouslySetInnerHTML={{ __html: heading }} /> : ''}
            { message ? <p slot="message" dangerouslySetInnerHTML={{ __html: message }} /> : ''}
        </OmProTip>
    );
};

export const pageQuery = graphql`
fragment ProTip on Contentstack_pro_tip {
    id
    uid
    heading
    message
}
`;

export default ProTip;
