import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    getPercentageOfItemsCompletedByJourneyId,
    getRemainingTimeByJourneyId,
    getTotalJourneyItemsCompletedByJourneyId,
} from '@om/redux/selectors/financialEducation/journey';

import { OmIcon } from '@om/component-library-react';
import { ThemeContext } from '../../../context/ThemeContext.js';

import './FinEdProgressBar.scss';

const getProgressbarText = (
    numberOfStepsCompleted,
    totalNumberOfSteps,
    remainingJourneyTime
) => {
    if (
        numberOfStepsCompleted > 0 &&
        totalNumberOfSteps === numberOfStepsCompleted
    ) {
        return (
            <h6 className="text small no-margin">
                <strong>{numberOfStepsCompleted}/{totalNumberOfSteps}</strong> You can always
                come for a refresher
            </h6>
        );
    } else if (numberOfStepsCompleted > 0) {
        return (
            <h6 className="text small no-margin">
                <strong>{numberOfStepsCompleted}/{totalNumberOfSteps}</strong> Do the rest in{' '}
                <strong>
                    {remainingJourneyTime ? remainingJourneyTime : 0}{' '}
                    {remainingJourneyTime === 1 ? 'min' : 'mins'}
                </strong>
            </h6>
        );
    }

    return (
        <h6 className="text small no-margin">
            We all start somewhere, do yours in{' '}
            <strong>
                {remainingJourneyTime ? remainingJourneyTime : 0}{' '}
                {remainingJourneyTime === 1 ? 'min' : 'mins'}
            </strong>
        </h6>
    );
};

const FinEdProgressBar = ({ totalNumberOfSteps, journeyId }) => {

    const [loadProgressBar, setLoadProgressbar] = useState(false);
    
    const {
        numberOfStepsCompleted,
        completedPercentage,
        remainingJourneyTime,
    } = useSelector(
        createStructuredSelector({
            numberOfStepsCompleted: getTotalJourneyItemsCompletedByJourneyId(journeyId),
            completedPercentage: getPercentageOfItemsCompletedByJourneyId(journeyId),
            remainingJourneyTime: getRemainingTimeByJourneyId(journeyId),
        })
    );

    const theme = React.useContext(ThemeContext);

    useEffect(() => {
        setTimeout(() => {
            if (!loadProgressBar) {
                setLoadProgressbar(true);
            }
        }, 1000)
    });

    return (
        <div className={`${theme} progress-bar-wrapper`}>
            {
                loadProgressBar && <div
                    className="progress-bar-filler"
                    style={{
                        width: `${completedPercentage ? completedPercentage : 0}%`,
                    }}
                />
            }
            <div className="progress-bar-text">
                {numberOfStepsCompleted > 0 ? <OmIcon iconName="check" /> : ''}
                {getProgressbarText(
                    numberOfStepsCompleted,
                    totalNumberOfSteps,
                    remainingJourneyTime
                )}
            </div>
        </div>
    );
};

export default FinEdProgressBar;
