import React from 'react';
import { graphql } from 'gatsby';

import ApplicationContainer from '../ApplicationContainer';
import OMIFrames from '../iFrames';
import Quiz from '../quiz/Quiz';
import Carousel from '../Carousel';
import PodcastWrapper from './PodcastWrapper';
import Checklist from './checklist/Checklist';
import QuoteBlock from '../QuoteBlock';
import Paragraph from '../Paragraph';
import ProTip from '../ProTip';
import ArticleImage from '../ArticleImage';
import ArticleVideoContentWrapper from '../ArticleVideoContentWrapper';
import { Om1ColLayout } from '@om/component-library-react';

const onClickCustomEvent = (e, journeyItemId, isCustomCompleteBlock, onCompleteEvent) => {
    if (isCustomCompleteBlock) {
        const itemContainer = document.getElementById(journeyItemId);
        if (itemContainer) {
            const articleCardAnchor = itemContainer.querySelector('a.om-article-card-container');
            const downloadCardButton = itemContainer.querySelector('.om-download-card-container om-button');
            const productCardButton = itemContainer.querySelector('.om-product-card-button-container om-button');

            if (articleCardAnchor) {
                articleCardAnchor.setAttribute('target', '_blank');
            }

            if (downloadCardButton) {
                downloadCardButton.setAttribute('open-tab', 'true');
            }

            if (productCardButton) {
                productCardButton.setAttribute('open-tab', 'true');
            }
            
            const articleClicked = articleCardAnchor && (articleCardAnchor == e.target || articleCardAnchor.contains(e.target));
            const downloadClicked = downloadCardButton && (downloadCardButton == e.target || downloadCardButton.contains(e.target));
            const productClicked = productCardButton && (productCardButton == e.target || productCardButton.contains(e.target));

            (articleClicked || downloadClicked || productClicked) && onCompleteEvent();
        }
    }
}

const FinEdJourneyItemModularBlock = ({
    modularBlock,
    onCompleteEvent,
    journeyItemId,
    isCustomCompleteBlock
}) => {

    if (!modularBlock) {
        return '';
    }

    const {
        application_container_block,
        quiz_block,
        iframe_block,
        carousel_block,
        podcast_block,
        checklist_block,
        article_media_block,
        article_text_block,
        article_disclaimer_block
    } = modularBlock;

    const getBlock = () => {
        if (application_container_block != null) {
            return <ApplicationContainer 
                applicationContainerContent={application_container_block.application_container[0]} 
                isInContainer={true}
                onCompleteEvent={onCompleteEvent} />
        }

        if (quiz_block != null) {
            return <Quiz quizContent={quiz_block.quiz[0]} onCompleteEvent={onCompleteEvent} />
        }

        if (iframe_block != null) {
            return <OMIFrames iFramesContent={iframe_block.iframe[0]} onCompleteEvent={onCompleteEvent} />
        }

        if (carousel_block != null) {
            return <Carousel carouselContent={carousel_block.carousel[0]} />
        }

        if (podcast_block != null) {
            return <PodcastWrapper podcastWrapperContent={podcast_block} />
        }

        if (checklist_block != null) {
            return <Checklist checklistContent={checklist_block.checklist[0]} />
        }

        if (article_text_block != null) {
            if (article_text_block.article_text[0].__typename == 'Contentstack_pro_tip') {
                return (
                    <ProTip proTipContent={article_text_block.article_text[0]} />
                )
            }

            if (article_text_block.article_text[0].__typename == 'Contentstack_one_column_text_paragraph') {
                return (
                    <Paragraph paragraphContent={article_text_block.article_text[0]} />
                );
            }

            if (article_text_block.article_text[0].__typename == 'Contentstack_quote_block') {
                return (
                    <QuoteBlock quoteBlockContent={article_text_block.article_text[0]} />
                )
            }
        
            return ''
        }

        if (article_media_block != null) {
            if (article_media_block.article_media[0].__typename == 'Contentstack_article_video_content_wrapper') {
                return (
                    <ArticleVideoContentWrapper
                        articleVideoContentWrapperContent={article_media_block.article_media[0]}
                    />
                )
            }

            if (article_media_block.article_media[0].__typename == 'Contentstack_article_image') {
                return (
                    <ArticleImage articleImageContent={article_media_block.article_media[0]} />
                )
            }
            
            return ''
        }

        if (article_disclaimer_block != null) {
            return (
                <Om1ColLayout
                    className="block extra-large-margin-top extra-large-margin-bottom"
                    narrow={true}
                >
                    <p className="no-margin muted" dangerouslySetInnerHTML={{ __html: article_disclaimer_block.disclaimer }} />
                </Om1ColLayout>
            )
        }

        return '';
    }

    return (
        <div onClick={(e) => onClickCustomEvent(e, journeyItemId, isCustomCompleteBlock, onCompleteEvent)}>
            {getBlock()}
        </div>
    )
    
}

export const pageQuery = graphql`
    fragment FinEdJourneyItemModularBlock on Contentstack_journey_accordion_item_modular_blocks {
        application_container_block {
            application_container {
                ...ApplicationContainer
            }
        }
        quiz_block {
            quiz {
                ...Quiz
            }
        }
        iframe_block {
          iframe {
            __typename
            ...IFrame
            ...TabsIFrame
            ...VideoOverlayWrapper
          }
        }
        carousel_block {
            carousel {
                __typename
                ...ProductCarousel,
                ...DownloadCarousel,
                ...ArticleCarousel,
                ...TabsProductCarousel,
            }
        }
        podcast_block {
            podcast_series_id
            podcast_episode_id
        }
        checklist_block {
            checklist {
                __typename
                ...Checklist
            }
        }
        article_media_block {
            article_media {
                __typename
                ...ArticleImage
                ...ArticleVideoContentWrapper
            }

        }
        article_text_block {
            article_text {
                __typename
                ...QuoteBlock
                ...Paragraph
                ...ProTip
            }
        }
        article_disclaimer_block {
            disclaimer
        }
    }
`

export default FinEdJourneyItemModularBlock;
