import React from 'react';
import { OmPodcastWrapper } from '@om/component-library-react'

const PodcastWrapper = ({ podcastWrapperContent }) => {
    if (!podcastWrapperContent) {
        return '';
    }

    const {
        podcast_series_id,
        podcast_episode_id
    } = podcastWrapperContent

    return (
        <OmPodcastWrapper
            podcastSeriesId={podcast_series_id}
            podcastEpisodeId={podcast_episode_id} />
    )
}

export default PodcastWrapper;
