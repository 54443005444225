import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
    createFinancialEducationJourney,
    setDelightAnimationCountdownTime,
    setUpNextCountdownTime,
    toggleShowUpNextCountdownTime,
} from '../../../redux/actions/financialEducation/journey';
import {
    getdelightAnimationCountdownTimeByJourneyId,
    getUpNextRemainingCountdownTimeByJourneyId,
    isDelightAnimationCompletedByJourneyId,
    isJourneyCompletedAndAllAccordionsClosedByJourneyId,
    showUpNextCountdownTimerByJourneyId,
} from '@om/redux/selectors/financialEducation/journey';

import FinEdWizardHeader from '../components/financialEducation/finEdWizardHeader/FinEdWizardHeader';
import FinancialEducationLayout from '../layouts/FinancialEducationLayout';
import JourneyHeading from '../components/financialEducation/journeyHeading/JourneyHeading';
import VerticalAccordionStepper from '../components/financialEducation/verticalAccordionStepper/VerticalAccordionStepper';
import FinEdDelight from '../components/financialEducation/finEdDelight/FinEdDelight';
import UpNextCard from '../components/financialEducation/upNextCard/UpNextCard';
import FinEdDisclaimer from '../components/financialEducation/finEdDisclaimer/FinEdDisclaimer';

const getJourneyItems = (journey_items) => {
    return (
        journey_items &&
        journey_items.map((journeyItem) => ({
            id: journeyItem.uid,
            timeToComplete: journeyItem.time,
        }))
    );
};

const FinancialEducationJourneyPage = ({ data }) => {

    const {
        uid,
        theme,
        background_image,
        back_button_group,
        heading,
        description,
        icon,
        icon_name,
        journey_items,
        up_next_card,
        disclaimer
    } = data.contentstackFinancialEducationJourneyPage;

    const dispatch = useDispatch();

    const {
        upNextRemainingCountdownTime,
        showUpNextCountdownTimer,
        delightAnimationCountdownTime,
        isDelightAnimationCompleted,
        isJourneyCompletedAndAllAccordionsClosed,
    } = useSelector(
        createStructuredSelector({
            upNextRemainingCountdownTime: getUpNextRemainingCountdownTimeByJourneyId(uid),
            showUpNextCountdownTimer: showUpNextCountdownTimerByJourneyId(uid),
            delightAnimationCountdownTime: getdelightAnimationCountdownTimeByJourneyId(uid),
            isDelightAnimationCompleted: isDelightAnimationCompletedByJourneyId(uid),
            isJourneyCompletedAndAllAccordionsClosed: isJourneyCompletedAndAllAccordionsClosedByJourneyId(uid),
        })
    );

    useEffect(() => {
        if (journey_items) {
            dispatch(
                createFinancialEducationJourney(
                    uid,
                    getJourneyItems(journey_items)
                )
            );
        }
    }, [uid, journey_items, dispatch]);

    useEffect(() => {
        if (isJourneyCompletedAndAllAccordionsClosed) {
            if (delightAnimationCountdownTime === 0 && upNextRemainingCountdownTime === 15) {
                dispatch(toggleShowUpNextCountdownTime(uid));

                const endTextElement = document.getElementById('end-text');
                if (endTextElement && up_next_card && up_next_card[0]) {
                    endTextElement.scrollIntoView({behavior: 'smooth'});
                }
            } else if (delightAnimationCountdownTime > 0) {
                setTimeout(() => { dispatch(setDelightAnimationCountdownTime(uid, delightAnimationCountdownTime - 1)) }, 1000);
            }
        }
    }, [uid, upNextRemainingCountdownTime, isJourneyCompletedAndAllAccordionsClosed, delightAnimationCountdownTime, dispatch])

    return (
        <FinancialEducationLayout
            theme={theme}
            wizardHeader={
                <FinEdWizardHeader
                    displayMobileWizardHeader={true}
                    heading={heading}
                    backButton={back_button_group}
                />
            }
        >
            <div>
                <JourneyHeading
                    heading={heading ? heading : ''}
                    description={description ? description : ''}
                    icon={icon}
                    icon_name={icon_name}
                />
            </div>

            <div>
                {isJourneyCompletedAndAllAccordionsClosed &&
                    !isDelightAnimationCompleted && <FinEdDelight />}
            </div>
            <div>
                <VerticalAccordionStepper
                    journeyId={uid}
                    journeyItems={journey_items}
                    hasUpNextComponent={up_next_card && up_next_card[0] ? true : false}
                    accordionBackgroundSideImage={background_image}
                />
            </div>
            <div>
                {isJourneyCompletedAndAllAccordionsClosed &&
                    isDelightAnimationCompleted &&
                    (<UpNextCard
                        upNextCardContent={up_next_card && up_next_card[0]}
                        displayCancelButton={showUpNextCountdownTimer}
                        cancelTimerEvent={() => {
                            dispatch(toggleShowUpNextCountdownTime(uid));
                        }}
                    />
                    )}
            </div>
            <div>
                <FinEdDisclaimer disclaimerContent={disclaimer && disclaimer[0]}/> 
            </div>
        </FinancialEducationLayout>
    );
};

export const pageQuery = graphql`
    query FinancialEducationJourneyPageQuery($id: String!) {
        contentstackFinancialEducationJourneyPage(id: { eq: $id }) {
            id
            uid
            theme {
                ...Theme
            }
            back_button_group {
                heading
                url
            }
            heading
            background_image {
                ...File
            }
            description
            icon {
                ...File
            }
            icon_name
            journey_items {
                ...JourneyAccordionItem
            }
            up_next_card {
                ...UpNextCard
            }
            disclaimer {
                ...FinEdDisclaimer
            }
        }
    }
`;

export default FinancialEducationJourneyPage;
