import React from 'react';

import { ThemeContext } from '../../../context/ThemeContext';
import './FinEdBackground.scss';

const FinEdBackground = ({
    children,
    svgOne,
}) => {

    const theme = React.useContext(ThemeContext);

    return (
        <div className={`${theme} fin-ed-background`}>
            <div className="background">
                <div className="background-svg-one">{svgOne}</div>
            </div>
            <div className="content-overlay">{children}</div>
        </div>
    );
};

export default FinEdBackground;
